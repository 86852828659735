import * as React from "react"
import { Link } from "gatsby"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const NotFoundPage = () => {
  const [isMount, setMount] = React.useState(false);

  React.useEffect(() => {
    setMount(true);
  }, [])

  if (!isMount) {
    return (
      <div>loading</div>
    )
  }

  return (
    <div>Page Not Found</div>
  )
}

export default NotFoundPage
